<template>
  <div>
    <b-card>
      <feather-icon icon="TruckIcon" class="mr-50 mb-1" size="28" />
      <span style="font-size: 20px">ที่อยู่ในการจัดส่ง</span>
      <div v-for="(item, index) in addressData" :key="index">
        <b-form-radio
          :value="item.id"
          v-model="set_address"
          v-if="set_address == item.id"
          class="m-1"
        >
          {{ item.name }}| {{ item.phone }} <br />
          {{ item.detail }}
        </b-form-radio>
      </div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-address
        variant="outline-primary"
        size="sm"
      >
        เปลี่ยน
      </b-button>
      <b-alert
      variant="warning"
      show
      v-if="!set_address"
    >
      <h4 class="alert-heading">
        เลือกที่อยู่จัดส่ง
      </h4>
      <div class="alert-body">
        <span>กรุณาเลือกที่อยู่จัดส่ง</span>
      </div>
    </b-alert>
    </b-card>

    <b-modal
      id="modal-xl"
      ok-only
      ok-title="close"
      centered
      size="lg"
      :title="title"
      v-model="model_address_new"
    >
      <b-row>
        <b-col cols="6" class="mb-2">
          <label style="font-size: 16px">ชื่อ-สกุล</label>
          <input
            type="text"
            class="form-control"
            placeholder="ชื่อ-สกุล...."
            v-model="name"
          />
        </b-col>
        <b-col cols="6" class="mb-2">
          <label style="font-size: 16px">หมายเลขโทรศัพท์</label>
          <input
            type="tel"
            class="form-control"
            placeholder="เบอร์โทรศัพท์..."
            v-model="phone"
          />
        </b-col>
        <b-col cols="6" class="mb-2">
          <ThailandAutoComplete
            v-model="district"
            type="district"
            @select="select"
            label="ตำบล"
            color="#42b883"
            size="medium"
            placeholder="ตำบล..."
          />
        </b-col>
        <b-col cols="6" class="mb-2">
          <ThailandAutoComplete
            v-model="amphoe"
            type="amphoe"
            @select="select"
            label="อำเภอ"
            size="medium"
            placeholder="อำเภอ..."
          />
        </b-col>
        <b-col cols="6" class="mb-2">
          <ThailandAutoComplete
            v-model="province"
            type="province"
            @select="select"
            label="จังหวัด"
            color="#35495e"
            size="medium"
            placeholder="จังหวัด..."
          />
        </b-col>
        <b-col cols="6" class="mb-2">
          <ThailandAutoComplete
            v-model="zipcode"
            type="zipcode"
            @select="select"
            label="รหัสไปรษณีย์"
            color="#00a4e4"
            size="medium"
            placeholder="รหัสไปรษณีย์..."
          />
        </b-col>

        <b-col cols="12" class="mb-2">
          <label for="textarea-default">รายละเอียดที่อยู่</label>
          <b-form-textarea id="textarea-default" rows="3" v-model="detail" />
        </b-col>

        <b-col md="12">
          <b-col md="8">
            <code>
              <feather-icon icon="BellIcon" size="20" class="mr-50" />
              ปักหมุดตำแหน่งของคุณ เราจะจัดส่งสินค้าไปยังตำแหน่งที่ปักหมุดไว้
              กรุณาตรวจสอบตำแหน่งปักหมุดของคุณ หากปักหมุดไม่ตรง
              กรุณาคลิกที่แผนที่เพื่อแก้ไข
            </code>
          </b-col>

          <gmap-map
            :zoom="14"
            :center="center"
            style="width: 100%; height: 350px"
            @click="handleMapClick"
          >
            <gmap-marker :position="position"> </gmap-marker>
          </gmap-map>
        </b-col>
      </b-row>
      <br />
      <b-button
        v-if="id"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        @click="update()"
        variant="primary"
      >
        อัพเดทที่อยู่
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        @click="save()"
        variant="primary"
      >
        บันทึกที่อยู่
      </b-button>
    </b-modal>
    <b-modal
      id="modal-address"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="ที่อยู่ของฉัน"
      v-model="model_address"
    >
      <b-row>
        <b-col cols="12">
          <div v-for="(item, index) in addressData" :key="index" class="mb-1">
            <b-form-radio :value="item.id" v-model="set_address" checked>
              {{ item.name }}| {{ item.phone }} <br />
              {{ item.detail }}
            </b-form-radio>
            <code
              @click="
                id = item.id;
                center.lat = parseFloat(item.lat);
                center.lng = parseFloat(item.lng);
                position.lat = parseFloat(item.lat);
                position.lng = parseFloat(item.lng);
                district = item.district;
                amphoe = item.amphoe;
                province = item.province;
                zipcode = item.zipcode;
                name = item.name;
                detail = item.detail;
                phone = item.phone;
                address = item.address;
                model_address_new = true;
                title = 'แก้ไขที่อยู่';
              "
            >
              แก้ไข
            </code>
          </div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click="open_model_add()"
            variant="outline-primary"
          >
            ที่อยู่ใหม่
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import api from "@/api";
import ThailandAutoComplete from "vue-thailand-address-autocomplete";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAvatar,
  BFormFile,
  BFormSelect,
  BFormTimepicker,
  BFormCheckbox,
  BAlert,
  VBModal,
  BModal,
  BButton,
  BFormTextarea,
  BFormRadio,
  BCard,
  BCardText,
} from "bootstrap-vue";
import { flat } from "postcss-rtl/lib/affected-props";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    ThailandAutoComplete,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BFormCheckbox,
    BAvatar,
    BFormFile,
    BFormSelect,
    BFormTimepicker,
    BFormCheckbox,
    BAlert,
    BButton,
    BFormTextarea,
    BFormRadio,
    BCard,
    BCardText,
    google: gmapApi,
  },
  data() {
    return {
      id: null,
      dialog_address: true,
      center: { lat: 16.439688394027236, lng: 102.8289296386659 },
      position: { lat: 16.439688394027236, lng: 102.8289296386659 },
      district: "",
      amphoe: "",
      province: "",
      zipcode: "",
      name: "",
      detail: "",
      phone: "",
      address: "",
      set_address: "",
      addressData: [],
      data_profile: [],
      title: "ที่อยู่ใหม่",
      model_address_new: false,
      model_address: false,
    };
  },

  mounted() {
    this.data_profile = JSON.parse(localStorage.getItem("data_profile"));
    this.data_profile.sub;
    this.loadaddress();
  },
  methods: {
    open_model_add() {
      this.id = null;
      (this.center = { lat: 16.439688394027236, lng: 102.8289296386659 }),
        (this.position = { lat: 16.439688394027236, lng: 102.8289296386659 }),
        (this.district = "");
      this.amphoe = "";
      this.province = "";
      this.zipcode = "";
      this.name = null;
      this.detail = null;
      this.phone = null;
      this.address = null;
      this.title = "ที่อยู่ใหม่";
      this.model_address_new = true;
    },
    handleMapClick(e) {
      console.log(" e.latLng.lat() :>> ", e.latLng.lat());
      this.position.lat = e.latLng.lat();
      this.position.lng = e.latLng.lng();
    },
    select(address) {
      this.district = address.district;
      this.amphoe = address.amphoe;
      this.province = address.province;
      this.zipcode = address.zipcode;
    },
    loadaddress() {
      const params = {
        line_id: this.data_profile.sub,
        data: this.data_profile,
      };
      console.log('user_address_liff params :>> ', params);
      api
        .post("user_address_liff", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.user_address_liff :>> ", response.data);
          this.id = null;
          this.addressData = response.data.user_address;
          this.set_address = response.data.user_address[0].id;
          this.model_address_new = false;
          this.model_address = false;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    update() {
      let formData = new FormData();

      formData.append("id", this.id);
      formData.append("line_id", this.data_profile.sub);
      formData.append("lat", this.position.lat);
      formData.append("lng", this.position.lng);
      formData.append("name", this.name);
      formData.append("detail", this.detail);
      formData.append("phone", this.phone);
      formData.append("address", this.address);
      formData.append("district", this.district);
      formData.append("amphoe", this.amphoe);
      formData.append("province", this.province);
      formData.append("zipcode", this.zipcode);
      api
        .post("user_address_update", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.model_address_new = false;
          this.model_address = false;
          
          this.id = null;
          this.loadaddress();
          this.$swal({
            title: "อัพเดทที่อยู่สำเร็จ!",
            // text: "ทำรายการสำเร็จ!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: " โปรดลองใหม่อีกครั้ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    save() {
      let formData = new FormData();

      formData.append("line_id", this.data_profile.sub);
      formData.append("lat", this.position.lat);
      formData.append("lng", this.position.lng);
      formData.append("name", this.name);
      formData.append("detail", this.detail);
      formData.append("phone", this.phone);
      formData.append("address", this.address);
      formData.append("district", this.district);
      formData.append("amphoe", this.amphoe);
      formData.append("province", this.province);
      formData.append("zipcode", this.zipcode);
      api
        .post("user_address_add_liff", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.id = null;
          this.loadaddress();
          this.$swal({
            title: "เพิ่มที่อยู่สำเร็จ!",
            // text: "ทำรายการสำเร็จ!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: " โปรดลองใหม่อีกครั้ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
